<template>
    <div class="row">
        <div class="col-12 spacer-navigation spacer-footer">
            <h1 class="scale-6 spacer-80-60" :id="anchorTitle">
                {{ page.title }}
            </h1>
            <template v-if="restaurants?.length">
                <h2
                    class="scale-3 scale-3--bold-uppercase spacer-30"
                    :id="slugifyString(t('location.overview.restaurants'))"
                >
                    {{ t('location.overview.restaurants') }}
                </h2>
                <div class="spacer-180-120 restaurants">
                    <LocationTeaser
                        v-for="item in restaurants"
                        :teaser="item"
                    />
                </div>
            </template>
            <template v-if="page.info || shops?.length">
                <h2
                    class="scale-3 scale-3--bold-uppercase spacer-30"
                    :id="slugifyString(t('location.overview.shops'))"
                >
                    {{ t('location.overview.shops') }}
                </h2>
                <p class="scale-1 scale-1--lead spacer-30 overview__info">
                    {{ page.info }}
                </p>
                <div class="overview__grid">
                    <NuxtLink
                        v-for="shop in shops"
                        :to="shop.to"
                        class="d-flex item"
                        :target="getTarget(shop.to)"
                    >
                        <div class="d-flex flex-column item__content">
                            <span
                                class="equal-small-2 equal-small-2--bold-uppercase item__location"
                            >
                                {{ shop.location }}
                            </span>
                            <span class="scale-1 item__name">
                                {{ shop.name }}
                            </span>
                        </div>
                    </NuxtLink>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import { useWagtailClient } from '~/composables/useWagtailClient';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { getTarget } from '~/utils/externalLink';
import { useHrefLang } from '~/composables/seo/hreflang';
import { useAnchorTitle } from '~/composables/useAnchorTitle';
import { slugifyString } from '~/utils/slugify';

const { t } = useI18n();

const restaurants = computed(() => page?.value?.restaurants);
const shops = computed(() => page?.value?.shops);

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

useHrefLang(page?.value?.meta?.hreflang);

const anchorTitle = computed(() => useAnchorTitle(page?.value?.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.restaurants {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: clamp(50px, 0.69vw + 47.78px, 60px);
    row-gap: clamp(50px, 0.69vw + 47.78px, 60px);

    @include media-breakpoint-down(640) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.overview {
    &__info {
        width: 66%;

        @include media-breakpoint-down(640) {
            width: 100%;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        column-gap: clamp(35px, 0.35vw + 33.89px, 40px);
        row-gap: clamp(35px, 0.35vw + 33.89px, 40px);

        @include media-breakpoint-down(1200) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @include media-breakpoint-down(1040) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @include media-breakpoint-down(800) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include media-breakpoint-down(640) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}

.item {
    background-color: $color-dark-03;
    border-radius: 8px;
    padding: clamp(20px, 1.39vw + 15.56px, 40px);
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;

    &__content {
        margin: auto 0;
    }

    &__location {
        color: #999999;
    }

    &__name {
        text-transform: none;
    }
}
</style>
